import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'
import CrabSelectDropdown from './CrabSelectDropdown';

const CrabSelector = ({
  crabData,
  dropdownData,
  image,
  nextCrab,
  previousCrab,
}) => (
  <div className='
    lg:flex
    items-center
    lg:w-[80%]
    mx-auto
    justify-center
  '>
      <div className="
        grid
        grid-cols-12
        py-10
        max-w-lg
        mx-auto
        lg:mx-0
      ">
        <Link
          aria-label='Previous crab'
          to={`/crabs/${previousCrab}`}
          className="
            col-span-3
            self-center
            h-1/4
        ">
          <ChevronLeftIcon className='
            h-full
            mx-auto
          ' />
        </Link>
        <div className="
          col-span-6
          drop-shadow-3xl
        ">
          <GatsbyImage
            image={image}
            alt={crabData.fullname}
          />
        </div>
        <Link
          aria-label='Next crab'
          to={`/crabs/${nextCrab}`}
          className="
            col-span-3
            self-center
            h-1/4
        ">
          <ChevronRightIcon className='
            h-full
            mx-auto
          '/>
        </Link>
      </div>
    <div className='
      w-[80%]
      mx-auto
      lg:mx-0
      max-w-lg
    '>
      <h1>
        {crabData.fullname}
      </h1>
    <p className='
      text-lg
    '>
      {crabData.description}
    </p>
    </div>
  </div>
)

export default CrabSelector;
