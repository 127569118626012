import React from 'react';
import Layout from '../components/Layout';

import {
  getImage
} from 'gatsby-plugin-image';

import CardCombos from '../components/JokerCrab/CardCombos';
import CrabSelector from '../components/JokerCrab/CrabSelector';

import YoutubeEmbed from '../components/YoutubeEmbed';
import OceanSection from '../components/OceanSection';
import SandSection from '../components/SandSection';
import Metadata from '../components/Metadata';

const JokerCrab = ({ pageContext }) => {
  const {
    crabData,
    combos,
    nextCrab,
    previousCrab,
    dropdownData,
  } = pageContext;
  
  const image = getImage(crabData.imageData);

  return (
    <Layout>
      <Metadata
        title={crabData.fullname}
        slug={crabData.name}
        description={crabData.description_short}
        keywords={`${crabData.fullname}, ${crabData.scientific_name}`}
      />
      <SandSection>
        <CrabSelector
          crabData={crabData}
          dropdownData={dropdownData}
          image={image}
          nextCrab={nextCrab}
          previousCrab={previousCrab}
        />
        <h2 className='
          pt-8
          pb-6
        '>
          {`${crabData.fullname} In Action:`}
        </h2>

        <YoutubeEmbed
          src={crabData.youtube_link}
        />

      </SandSection>

      <OceanSection>

        <CardCombos
          crabData={crabData}
          combos={combos}
          image={image}
        />

      </OceanSection>

    </Layout>
  );
}

export default JokerCrab;
