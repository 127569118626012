import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const CardCombos = ({ crabData, combos, image }) => (
  <div className='
      md:pb-12
      lg:pb-20
    '>
    <h2 className='
      pb-12
      text-white
      md:text-3xl
    '>
      {`${crabData.fullname} Combos`}
    </h2>

    <div className='
      mx-auto
      w-[90%]
      flex-wrap
      max-w-7xl
      flex
      flex-row
      gap-10
      justify-center
      justify-items-center
    '>
      {combos.map(combo => (
        <div className='
          p-6
          max-w-sm
          rounded-3xl
          drop-shadow-3xl
          bg-gradient-to-bl
          from-ocean-foam
          to-ocean-light
        '>
          <div className='
            grid
            grid-cols-12
            grid-rows-12
            aspect-[3/4]
          '>
            <div className="
              drop-shadow-3xl
              col-start-1
              col-end-9
              row-start-1
              row-end-5
              aspect-[5/7]
            ">
              <GatsbyImage
                alt={crabData.fullname}
                image={image}
              />
            </div>
            <Link
              aria-label={`Go to ${combo.card.fullname}`}
              to={`/crabs/${combo.card.name}`}
              className="
                drop-shadow-3xl
                col-start-4
                col-end-13
                row-start-2
                row-end-7
                aspect-[5/7]
              "
            >
              <GatsbyImage
                alt={combo.card.fullname}
                image={getImage(combo.card.imageData)}
              />
            </Link>
          </div>

          <h3 className='
            italic
            pt-6
            text-white
            text-2xl
          '>
            {combo.name}
          </h3>
        </div>
      ))}
    </div>
  </div>
)

export default CardCombos;
